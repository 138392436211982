import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
import logo from './logo.svg';

const Unsubscribe = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const unsubscribe = (email) => {
    axios
      .post('https://genesisee.xyz/api/v1/website/unsubscribe', {
        email,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.code === 0) {
          messageApi.open({
            type: 'success',
            content: 'Unsubscribe Success',
          });
          setTimeout(() => {
            navigate('/subscribe?email=' + encodeURIComponent(email));
          }, 1000);
        } else {
          messageApi.open({
            type: 'error',
            content: 'Unsubscribe Error',
          });
        }
      });
  };

  return (
    <div>
      {contextHolder}
      <div style={{ width: window.innerWidth, display: 'flex' }}>
        <div
          style={{
            borderWidth: 1,
            borderColor: '#8b5cf6',
            borderStyle: 'solid',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 50,
            width: window.innerWidth * 0.8,
            height: 500,
            textAlign: 'center',
          }}
        >
          <img style={{ marginTop: 100 }} src={logo} alt="logo" width={400} />
          <div>
            <div
              style={{
                fontWeight: 700,
                fontStyle: 'normal',
                fontSize: 18,
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'left',
                marginTop: 20,
              }}
            >
              Do you want to unsubscribe from General promotional material?
            </div>
            <div
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'left',
                fontWeight: 400,
                width: 500,
                marginTop: 30,
                fontStyle: 'normal',
              }}
            >
              You'll stop receiving messages about General promotional material.
            </div>
            <div
              onClick={() => {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const myParam = urlParams.get('email');
                unsubscribe(myParam);
              }}
              style={{
                marginTop: 40,
                backgroundColor: '#8b5cf6',
                padding: '8px 8px',
                color: 'white',
                fontWeight: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 110,
                borderRadius: 8,
                cursor: 'pointer',
              }}
            >
              Unsubscribe
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;
