import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
import Unsubscribe from './unsubscribe';

function App() {
  const [chartHover, setChartHover] = useState(false);
  const [hotspotHover, setHotspotHover] = useState(false);
  const [subscriptionHover, setSubsriptionHover] = useState(false);
  const [advisorHover, setAdvisorHover] = useState(false);
  const [twitterHover, setTwitterHover] = useState(false);
  const [mediumHover, setMediumHover] = useState(false);
  const [discordHover, setDiscordHover] = useState(false);
  const [githubHover, setGithubHover] = useState(false);
  const [userMail, setUserMail] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', (event) => {
      const leftCloud = document.getElementById('leftcloud');
      const rightCloud = document.getElementById('rightCloud');
      const leftBehindCloud = document.getElementById('leftbehindcloud');
      const rightBehindCloud = document.getElementById('rightbehindcloud');
      const robot = document.getElementById('robot');
      if (leftCloud?.style) {
        let xMove = 0;
        if (event.clientX === window.innerWidth / 2) {
          xMove = 0;
        }
        if (
          event.clientX < window.innerWidth / 2 &&
          event.clientX > window.innerWidth / 4
        ) {
          xMove = -(
            (50 * (window.innerWidth / 2 - event.clientX)) /
            (window.innerWidth / 2)
          );
        }
        if (event.clientX < window.innerWidth / 4) {
          xMove =
            -25 -
            (20 * (window.innerWidth / 4 - event.clientX)) /
              (window.innerWidth / 2);
        }
        if (
          event.clientX > window.innerWidth / 2 &&
          event.clientX < (3 * window.innerWidth) / 4
        ) {
          xMove =
            (50 * (event.clientX - window.innerWidth / 2)) /
            (window.innerWidth / 2);
        }
        if (event.clientX > (3 * window.innerWidth) / 4) {
          xMove =
            25 +
            (20 * (event.clientX - (3 * window.innerWidth) / 4)) /
              (window.innerWidth / 2);
        }
        const leftCloudMoveX = -40 + xMove;
        const leftCloudMoveY =
          parseFloat(leftCloud.style.top.replace('px', '') || 260) +
            event.movementY / 200 >
          370
            ? 370
            : parseFloat(leftCloud.style.top.replace('px', '') || 260) +
              event.movementY / 200;
        leftCloud.style.left = leftCloudMoveX + 'px';
        leftCloud.style.top =
          leftCloudMoveY < 250 ? 250 : leftCloudMoveY + 'px';

        const leftBehindCloudMoveX = -40 + (xMove * 2) / 3;
        const leftBehindCloudMoveY =
          parseFloat(leftBehindCloud.style.top.replace('px', '') || 250) +
            event.movementY / 300 >
          360
            ? 360
            : parseFloat(leftBehindCloud.style.top.replace('px', '') || 250) +
              event.movementY / 300;
        leftBehindCloud.style.left = leftBehindCloudMoveX + 'px';
        leftBehindCloud.style.top =
          leftBehindCloudMoveY < 240 ? 240 : leftBehindCloudMoveY + 'px';

        const rightBehindCloudMoveX = 40 + (xMove * 2) / 3;
        const rightBehindCloudMoveY =
          parseFloat(rightBehindCloud.style.top.replace('px', '') || 250) +
            event.movementY / 300 >
          340
            ? 340
            : parseFloat(rightBehindCloud.style.top.replace('px', '') || 250) +
              event.movementY / 300;
        rightBehindCloud.style.left = rightBehindCloudMoveX + 'px';
        rightBehindCloud.style.top =
          rightBehindCloudMoveY < 240 ? 240 : rightBehindCloudMoveY + 'px';

        const rightCloudMoveX = 40 + xMove;
        const rightCloudMoveY =
          parseFloat(rightCloud.style.top.replace('px', '') || 370) +
            event.movementY / 200 >
          460
            ? 460
            : parseFloat(rightCloud.style.top.replace('px', '') || 370) +
              event.movementY / 200;
        rightCloud.style.left = rightCloudMoveX + 'px';
        rightCloud.style.top =
          rightCloudMoveY < 360 ? 360 : rightCloudMoveY + 'px';

        const robotMoveX = (xMove * 2) / 3;
        const robotMoveY =
          parseFloat(robot.style.top.replace('px', '') || 0) +
            event.movementY / 200 <
          -10
            ? -10
            : parseFloat(robot.style.top.replace('px', '') || 0) +
              event.movementY / 200;
        robot.style.left = robotMoveX + 'px';
        robot.style.top = robotMoveY > 10 ? 10 : robotMoveY + 'px';
      }
    });
  });

  const subscribe = (email) => {
    axios
      .post('https://genesisee.xyz/api/v1/website/subscribe', {
        email,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.code === 0) {
          messageApi.open({
            type: 'success',
            content: 'Subscribe Success',
          });
        } else {
          messageApi.open({
            type: 'error',
            content: 'Subscribe Error',
          });
        }
      });
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <div className="App">
              {contextHolder}
              <header className="App-header">
                <img src={logo} style={{ cursor: 'pointer' }} alt="logo" />
                <img
                  style={{
                    marginLeft: 'auto',
                    cursor: 'not-allowed',
                    zIndex: 1000,
                  }}
                  src="/headerbutton.png"
                  width={150}
                  alt="logo"
                />
              </header>
              <div className="App-empty">
                <img
                  src="/leftshadow.png"
                  width="240"
                  className="App-leftshadow"
                  alt="logo"
                />
              </div>
              <div className="App-empty" style={{ textAlign: 'center' }}>
                <img
                  src="/rightshadow.png"
                  className="App-rightshadow"
                  alt="logo"
                />
              </div>
              <div className="App-empty">
                <img
                  id="leftbehindcloud"
                  src="/leftbehindcloud.png"
                  width="690"
                  className="App-leftbehindcloud"
                  alt="logo"
                />
              </div>
              <div className="App-empty">
                <img
                  id="leftcloud"
                  src="/leftcloud.png"
                  width="575"
                  className="App-leftcloud"
                  alt="logo"
                />
              </div>
              <div className="App-empty" style={{ textAlign: 'right' }}>
                <img
                  id="rightbehindcloud"
                  src="/rightbehindcloud.png"
                  width="589"
                  className="App-leftcloud"
                  alt="logo"
                />
              </div>
              <div className="App-empty" style={{ textAlign: 'right' }}>
                <img
                  id="rightCloud"
                  src="/rightcloud.png"
                  width="692"
                  className="App-rightcloud"
                  alt="logo"
                />
              </div>
              <div className="App-empty-wrapper">
                <div className="App-empty-wrapper">
                  <img
                    id="robot"
                    src="/robot.png"
                    width="507"
                    style={{ position: 'relative', zIndex: 1, marginTop: 144 }}
                    alt="logo"
                  />
                </div>
                {!chartHover && (
                  <div
                    onMouseEnter={() => setChartHover(true)}
                    className="App-empty-wrapper"
                  >
                    <img
                      src="/chartwhite.png"
                      width="240"
                      className="App-chartwhite"
                      alt="logo"
                    />
                  </div>
                )}
                {chartHover && (
                  <div
                    onMouseLeave={() => setChartHover(false)}
                    className="App-empty-wrapper"
                  >
                    <img
                      src="/chartpurple.png"
                      width="240"
                      className="App-chartwhite"
                      alt="logo"
                    />
                    <div className="App-robot-chart-title">
                      Chart comparison
                    </div>
                  </div>
                )}
                {!hotspotHover && (
                  <div
                    onMouseEnter={() => setHotspotHover(true)}
                    className="App-empty-wrapper"
                  >
                    <img
                      src="/hotspotwhite.png"
                      width="310"
                      className="App-hotspotwhite"
                      alt="logo"
                    />
                  </div>
                )}
                {hotspotHover && (
                  <div
                    onMouseLeave={() => setHotspotHover(false)}
                    className="App-empty-wrapper"
                  >
                    <img
                      src="/hotspotpurple.png"
                      width="310"
                      className="App-hotspotwhite"
                      alt="logo"
                    />
                    <div className="App-robot-hotspot-title">
                      Hotspot tracking
                    </div>
                  </div>
                )}
                {!subscriptionHover && (
                  <div
                    onMouseEnter={() => setSubsriptionHover(true)}
                    className="App-empty-wrapper"
                  >
                    <img
                      src="/subwhite.png"
                      width="310"
                      className="App-subwhite"
                      alt="logo"
                    />
                  </div>
                )}
                {subscriptionHover && (
                  <div
                    onMouseLeave={() => setSubsriptionHover(false)}
                    className="App-empty-wrapper"
                  >
                    <img
                      src="/subpurple.png"
                      width="310"
                      className="App-subwhite"
                      alt="logo"
                    />
                    <div className="App-robot-sub-title">Subscription push</div>
                  </div>
                )}
                {!advisorHover && (
                  <div
                    onMouseEnter={() => setAdvisorHover(true)}
                    className="App-empty-wrapper"
                  >
                    <img
                      src="/advisorwhite.png"
                      width="398"
                      className="App-advisorwhite"
                      alt="logo"
                    />
                  </div>
                )}
                {advisorHover && (
                  <div
                    onMouseLeave={() => setAdvisorHover(false)}
                    className="App-empty-wrapper"
                  >
                    <img
                      src="/advisorpurple.png"
                      width="398"
                      className="App-advisorwhite"
                      alt="logo"
                    />
                    <div className="App-robot-ai-title">
                      AI investment advisor
                    </div>
                  </div>
                )}
              </div>
              <div className="App-title">
                SEEING THE{' '}
                <span
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    color: 'rgba(139, 92, 246, 1)',
                    backgroundColor: 'rgba(255, 355, 255, 0.2)',
                    border: '1px solid #8B5CF6',
                    boxSizing: 'border-box',
                  }}
                >
                  FUTURE
                </span>
              </div>
              <div className="App-empty-wrapper">
                <img
                  src="/rect.png"
                  width="7"
                  style={{
                    position: 'relative',
                    left: 59,
                    top: -7,
                    zIndex: 1000,
                  }}
                  alt="logo"
                />
              </div>
              <div className="App-empty-wrapper">
                <img
                  src="/rect.png"
                  width="7"
                  style={{
                    position: 'relative',
                    left: 59,
                    top: -111,
                    zIndex: 1000,
                  }}
                  alt="logo"
                />
              </div>
              <div className="App-empty-wrapper">
                <img
                  src="/rect.png"
                  width="7"
                  style={{
                    position: 'relative',
                    left: 402,
                    top: -111,
                    zIndex: 1000,
                  }}
                  alt="logo"
                />
              </div>
              <div className="App-empty-wrapper">
                <img
                  src="/rect.png"
                  width="7"
                  style={{
                    position: 'relative',
                    left: 402,
                    top: -7,
                    zIndex: 1000,
                  }}
                  alt="logo"
                />
              </div>
              <div className="App-subtitle">CAPTURING THE VALUE</div>
              <div className="App-desc">
                Experience the future of Web3 investing with GenesiSee, a
                breakthrough
              </div>
              <div className="App-desc" style={{ marginTop: 0 }}>
                data analysis platform designed for daily investors.
              </div>
              <div className="App-startbtn-wrapper">
                <img
                  src="/Button.png"
                  width="320"
                  className="App-startbtn"
                  alt="logo"
                />
              </div>
              <div className="App-section-title" style={{ marginTop: 160 }}>
                INTUITIVE AI-POWERED INTERFACE
              </div>
              <div className="App-section-desc" style={{ marginTop: 24 }}>
                Embrace the beauty of effortless navigation with GenesiSee's
                cutting-edge AI-driven user interface. Expertly designed to
              </div>
              <div className="App-section-desc">
                make complex data analysis accessible, even for newcomers.
                Discover the extraordinary with unparalleled ease.
              </div>
              <div style={{ textAlign: 'center', marginTop: 65 }}>
                <video
                  width="1000"
                  height="710"
                  style={{ borderRadius: 28, position: 'relative', zIndex: 10 }}
                  autoPlay
                  loop
                  muted
                >
                  <source src="/1_dot.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="App-empty">
                <div className="App-video-background" />
              </div>
              <div style={{ display: 'flex', marginTop: 206 }}>
                <div style={{ marginTop: 50, width: 524, marginLeft: 'auto' }}>
                  <div
                    className="App-section-title"
                    style={{ textAlign: 'left' }}
                  >
                    All-Encompassing
                  </div>
                  <div
                    className="App-section-title"
                    style={{ textAlign: 'left' }}
                  >
                    Data Fusion
                  </div>
                  <div
                    className="App-section-desc"
                    style={{ textAlign: 'left', marginTop: 32 }}
                  >
                    Stay informed and ahead of the competition with our
                    comprehensive data amalgamation, harmoniously blending
                    on-chain and off-chain data sources. Unveil a complete
                    perspective of projects, empowering you to make astute
                    investment choices.
                  </div>
                </div>
                <div className="App-fusion-container">
                  <video
                    width="642"
                    height="456"
                    style={{
                      borderRadius: 20,
                      position: 'relative',
                      zIndex: 10,
                    }}
                    autoPlay
                    loop
                    muted
                  >
                    <source src="/2_dot.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className="App-empty">
                <div className="App-video-background-two" />
              </div>
              <div style={{ display: 'flex', marginTop: 320 }}>
                <div className="App-comparison-container">
                  <video
                    width="642"
                    height="456"
                    style={{
                      borderRadius: 20,
                      position: 'relative',
                      zIndex: 10,
                    }}
                    autoPlay
                    loop
                    muted
                  >
                    <source src="/3_dot.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div
                  style={{
                    marginTop: 40,
                    width: 550,
                    marginLeft: 50,
                    marginRight: 'auto',
                  }}
                >
                  <div
                    className="App-section-title"
                    style={{ textAlign: 'left' }}
                  >
                    Multi-Faceted
                  </div>
                  <div
                    className="App-section-title"
                    style={{ textAlign: 'left' }}
                  >
                    Project
                  </div>
                  <div
                    className="App-section-title"
                    style={{ textAlign: 'left' }}
                  >
                    Comparison
                  </div>
                  <div
                    className="App-section-desc"
                    style={{ textAlign: 'left', marginTop: 32 }}
                  >
                    Explore the innovative world of Web3 projects through
                    GenesiSee's multi-dimensional comparison tool. Effortlessly
                    visualize each project's inherent value, providing you with
                    the essential information for informed decision-making.
                  </div>
                </div>
              </div>
              <div className="App-empty">
                <div className="App-video-background-three" />
              </div>
              <div
                className="App-section-title"
                style={{
                  marginTop: 214,
                  width: 1200,
                  textAlign: 'left',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                Why Choose US？
              </div>
              <div className="App-why-desc">
                We believe everyone should have the opportunity to skillfully
                navigate the exciting world of Web3 investing. We focus on the
                needs of non-professional investors, turning complex information
                into understandable insights. With GenesiSee, you can make
                data-driven decisions that enhance your Web3 investing
                experience.
              </div>
              <div className="App-more-block">
                <div className="App-empty">
                  <img src="/lefttopcircle.png" width="226" alt="" />
                </div>
                <div style={{ display: 'flex' }}>
                  <div className="App-more-left">
                    <div className="App-more-title">
                      Not Just Investing Seeing the Future
                    </div>
                    <div className="App-more-desc">
                      Embrace the GenesiSee revolution and reimagine your Web3
                      investment journey. Dive into the digital world to compare
                      complexity and capture the power of digital investing.
                    </div>
                  </div>
                  <div className="App-more-right">
                    <div className="App-more-btn">
                      <img src="/arrow.png" width="24" alt="" />
                    </div>
                    <div className="App-more-comming">COMMING SOON</div>
                  </div>
                </div>
              </div>
              <div className="App-footer">
                <div
                  className="App-footer-upper"
                  style={{ width: windowSize.width - 200 }}
                >
                  <div className="App-footer-left">
                    <img src={logo} alt="" />
                    <div className="App-footer-left-desc">
                      Embrace the GenesiSee revolution and reimagine your Web3
                      investing journey.
                    </div>
                    <div className="App-footer-social">
                      <img
                        onClick={() => {
                          window.open('https://twitter.com/0xGenesiSee');
                        }}
                        onMouseEnter={() => {
                          setTwitterHover(true);
                        }}
                        onMouseLeave={() => {
                          setTwitterHover(false);
                        }}
                        style={{ cursor: 'pointer' }}
                        src={
                          twitterHover
                            ? '/twitterfooterh.png'
                            : '/twitterfooter.png'
                        }
                        width="32"
                        alt=""
                      />
                      {/* <img
                onClick={() => {
                  window.open('https://twitter.com/0xGenesiSee');
                }}
                style={{ marginLeft: 4 }}
                className="App-footer-social-link"
                src="/telegramfooter.png"
                width="32"
                alt=""
              /> */}
                      <img
                        onClick={() => {
                          window.open('https://medium.com/@0xgenesisee');
                        }}
                        onMouseEnter={() => {
                          setMediumHover(true);
                        }}
                        onMouseLeave={() => {
                          setMediumHover(false);
                        }}
                        style={{ marginLeft: 4, cursor: 'pointer' }}
                        src={
                          mediumHover
                            ? '/mediumfooterh.png'
                            : '/mediumfooter.png'
                        }
                        width="32"
                        alt=""
                      />
                      <img
                        onClick={() => {
                          window.open(
                            'https://discord.com/channels/1107489470015606866'
                          );
                        }}
                        onMouseEnter={() => {
                          setDiscordHover(true);
                        }}
                        onMouseLeave={() => {
                          setDiscordHover(false);
                        }}
                        style={{ marginLeft: 4, cursor: 'pointer' }}
                        src={
                          discordHover
                            ? '/discordfooterh.png'
                            : '/discordfooter.png'
                        }
                        width="32"
                        alt=""
                      />
                      <img
                        onClick={() => {
                          window.open('https://github.com/genesisee');
                        }}
                        onMouseEnter={() => {
                          setGithubHover(true);
                        }}
                        onMouseLeave={() => {
                          setGithubHover(false);
                        }}
                        style={{ marginLeft: 4, cursor: 'pointer' }}
                        src={
                          githubHover
                            ? '/githubfooterh.png'
                            : '/githubfooter.png'
                        }
                        width="32"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="App-footer-right">
                    <div className="App-footer-right-title">Subsribe</div>
                    <div className="App-footer-right-desc">
                      Subscribe to the newsletter to hear about relation labs
                      updates and events
                    </div>
                    <div style={{ display: 'flex', marginTop: 16 }}>
                      <input
                        value={userMail}
                        onChange={(e) => {
                          setUserMail(e.target.value);
                        }}
                        className="App-footer-input"
                        placeholder="Enter your email address"
                      />
                      <div
                        onClick={() => {
                          if (userMail === '') {
                            messageApi.open({
                              type: 'error',
                              content: 'Please enter your email address',
                            });
                            return;
                          }
                          const emailRegex =
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          if (emailRegex.test(userMail)) {
                            subscribe(userMail);
                          } else {
                            messageApi.open({
                              type: 'error',
                              content: 'Wrong address',
                            });
                            return;
                          }
                        }}
                        className="App-footer-btn"
                      >
                        Send
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="App-footer-divider"
                  style={{ width: window.innerWidth * 0.9 }}
                />
                <div className="App-footer-copyright">
                  Copyright © 2023-2023 GenesiSee
                </div>
              </div>
            </div>
          }
          exact
          path="/"
        ></Route>
        <Route element={<Unsubscribe />} exact path="/unsubscribe"></Route>
        <Route
          element={
            <div>
              {contextHolder}
              <div style={{ width: window.innerWidth, display: 'flex' }}>
                <div
                  style={{
                    borderWidth: 1,
                    borderColor: '#8b5cf6',
                    borderStyle: 'solid',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 50,
                    width: window.innerWidth * 0.8,
                    height: 500,
                    textAlign: 'center',
                  }}
                >
                  <img
                    style={{ marginTop: 100 }}
                    src={logo}
                    alt="logo"
                    width={400}
                  />
                  <div>
                    <div
                      style={{
                        fontWeight: 700,
                        fontStyle: 'normal',
                        fontSize: 18,
                        width: 400,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: 'left',
                        marginTop: 20,
                      }}
                    >
                      Subscribe to General promotional material
                    </div>
                    <div
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: 'left',
                        fontWeight: 400,
                        width: 400,
                        fontSize: 13,
                        marginTop: 30,
                        fontStyle: 'normal',
                      }}
                    >
                      Subscribe to receive our General promotional material
                      messages
                    </div>
                    <div
                      onClick={() => {
                        const queryString = window.location.search;
                        const urlParams = new URLSearchParams(queryString);
                        const myParam = urlParams.get('email');
                        subscribe(myParam);
                      }}
                      style={{
                        marginTop: 40,
                        backgroundColor: '#8b5cf6',
                        padding: '8px 8px',
                        color: 'white',
                        fontWeight: 400,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: 110,
                        borderRadius: 8,
                        cursor: 'pointer',
                      }}
                    >
                      Subscribe
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          exact
          path="/subscribe"
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
